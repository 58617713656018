import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import '@popperjs/core'
import { ValidationProvider } from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { initKeycloak, KeycloakInstance } from '@/keycloak'
import { updateAuthState } from '@/authState'

Vue.config.productionTip = false

library.add(faCircleArrowRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('ValidationProvider', ValidationProvider)

initKeycloak(authenticated => {
  updateAuthState(authenticated)

  if (authenticated) {
    setInterval(() => {
      KeycloakInstance.updateToken(30).catch(() => {
        console.error('El token expiró o no pudo actualizarse. Redirigiendo al login.')
        KeycloakInstance.login()
      })
    }, 10000)
  }

  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})
