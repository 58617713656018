<template>
  <footer>
    <p class="text-small">&copy; Oficina de Cumplimiento y Seguridad, SOLEM S.A. 2025</p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>
