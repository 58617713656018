import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
  url: `${process.env.VUE_APP_KC_URL}`,
  realm: `${process.env.VUE_APP_KC_REALM}`,
  clientId: `${process.env.VUE_APP_KC_CLIENT_ID}`
})

export const KeycloakInstance = keycloak

export const initKeycloak = (onAuthenticatedCallback) => {
  if (keycloak.initialized) {
    // console.warn('Keycloak ya está inicializado.')
    onAuthenticatedCallback(keycloak.authenticated)
    return
  }

  keycloak
    .init({
      onLoad: 'check-sso',
      checkLoginIframe: false
    })
    .then(authenticated => {
      keycloak.initialized = true
      onAuthenticatedCallback(authenticated)
      // console.log(keycloak)
    })
    .catch(err => {
      console.error('Error inicializando Keycloak:', err)
    })
}

export const updateToken = (minValidity = 30) => {
  return keycloak.updateToken(minValidity)
}

keycloak.initialized = false
