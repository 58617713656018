import Vue from 'vue'
import Router from 'vue-router'
import { KeycloakInstance } from '@/keycloak'

Vue.use(Router)

export const requireAuth = async (to, from, next) => {
  try {
    if (KeycloakInstance.authenticated) {
      next()
    } else {
      await KeycloakInstance.login({ redirectUri: window.location.origin + to.fullPath })
    }
  } catch (error) {
    console.error('Error durante la autenticación:', error)
    next(false)
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'landing',
      component: () => import('@/components/Landing')
    },
    {
      path: '/forms/compliance/:typeRequest(consulta|denuncia)',
      name: 'forms',
      component: () => import('@/components/Denuncia')
    },
    {
      path: '/forms/leykarin/:typeRequest(consulta|denuncia)',
      name: 'formskarin',
      component: () => import('@/components/DenunciaKarin'),
      beforeEnter: requireAuth
    },
    {
      path: '/resultado/:flow(compliance|leykarin)/:typeRequest/:success(success|error)/:message',
      name: 'resultado',
      component: () => import('@/components/Resultado')
    },
    {
      path: '/*',
      redirect: '/home'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
